<template>
    <div class="page">
        <div class="centercontainer">
            <a-form-model layout="vertical" :rules="dRules" :model="dFormData" ref="myForm">
                <a-form-model-item class="form-item" label="登录密码" prop="password">
                    <a-input v-model="dFormData.password" type="password" placeholder="请输入密码">
                        <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)" />
                    </a-input>
                </a-form-model-item>
                <a-form-model-item class="form-item">
                    <a-button type="primary" @click="submitForm('myForm')">重置密码</a-button>
                </a-form-model-item>
            </a-form-model>
        </div>
    </div>
</template>

<script>

    import { resetPassword } from "@/api/api"
    import { strIsEmpty } from '@/utils/my-utils';
    import {isMobile} from '@/utils/validate';

    export default {
        name: "ResetPassword",
        data() {
            return {
                dPhone: this.$route.params.phone,
                dToken: this.$route.params.token,
                dFormData: {
                    password: '',
                },
                dRules: {
                    password: [
                        { required: true, message: '请输入登录密码', trigger: 'blur' },
                        { min: 6, max: 12, message: '长度在 6 到 12 个字符', trigger: 'blur' }
                    ]
                }
            }
        },
        methods: {
            /** 页面初始化 */
            pageInit:function () {
                console.log('ResetPassword的pageInit函数， phone=' + this.dPhone + '   token = ' + this.dToken);
            },
            /** 提交表单 */
            submitForm: function (formName) {
                let that = this;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        that.resetPassword();
                    } else {
                        return false;
                    }
                });
            },
            /**  重置密码 */
            resetPassword: function () {
                let that = this;

                if(strIsEmpty(this.dPhone)){
                    this.$message.error('该页面已过期，没有可用的手机号码参数');
                    return;
                }
                if(strIsEmpty(this.dToken)){
                    this.$message.error('该页面已过期，没有可用的重置密码Token');
                    return;
                }

                let params = {};
                params.account = this.dPhone;
                params.retrieveToken = this.dToken;
                params.password = this.dFormData.password;
                params.loginType = 1;

                resetPassword(params).then((res) => {
                    console.log('resetPassword返回结果 = ' + JSON.stringify(res));
                    if (res.success) {
                        if(res.code == 200){
                            this.$alert('密码修改成功，请登录', '提示', {
                                confirmButtonText: '确定',
                                callback: action => {
                                    that.$router.push("Login");
                                }
                            });
                        }else{
                            // code: 1=该手机没有注册, 2=无效用户, 3=token过期或无效
                            switch (res.code) {
                                case 1:
                                    this.$message.error(res.message);
                                    break;
                                case 2:
                                    this.$message.error(res.message);
                                    break;
                                case 3:
                                    this.$message.error(res.message);
                                    break;
                            }
                        }
                    } else {
                        this.$message.error('重置密码失败，请重试');
                    }
                });

            },
        },
        created() {
            this.pageInit();
        },
    }
</script>

<style lang="less" scoped>
    .page{
        position: absolute;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px !important;
        background-color: #ffffff;
        .form-item{
            max-width: 300px;
        }
    }
</style>
